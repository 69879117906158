import {AfterViewInit, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {CourseService} from '../../shared/services/course.service';
import {AlertService} from '../../shared/services/alert.service';
import {StudentService} from '../../shared/services/student.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StorageService} from '../../shared/services/storage.service';
import {AuthService} from '../../shared/services/auth.service';
import {GlobalService} from '../../shared/services/global.service';

@Component({
  selector: 'ps-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public signup: boolean;
  public registrationForm: FormGroup;
  public registrationOtpForm: FormGroup;
  public loginForm: FormGroup;
  enrollmentVerifyForm: FormGroup;
  updatePasswordForm: FormGroup;
  courses: any = [];
  submitting: any = false;
  submittingOtp: any = false;
  submittingLogin: any = false;
  agreeTermsCondtions: any = false;
  submitted: any = false;
  submittedOtp: any = false;
  submittedLogin: any = false;
  decodedOtp: any;
  timerSixtySeconds: number = 60;
  interval: any;
  @ViewChild('content') templateRef: TemplateRef<any>;
  responseRegister: any;
  isBusy: any = false;
  orderIdTemp: any = false;
  modalType: any;
  directStudentData: any;
  submittedEnrollmentNoVerify: any = false;
  decodedResponseOtp: any;
  inputTypePassword: any = 'password';
  inputTypeConfirmPassword: any = 'password';
  submittingPassword: any = false;

  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private courseService: CourseService,
              public alertService: AlertService,
              private studentService: StudentService,
              public modalService: NgbModal,
              private authService: AuthService,
              private globalService: GlobalService,
              private router: Router) {
    const url = this.route.snapshot.url;
    const queryParams  = this.route.snapshot.queryParamMap;
    const orderId = queryParams.get('order_id');
    if(orderId) {
      this.checkOrderId(orderId);
    }
    if (url[0].path === 'simple-register') {
      this.signup = true;
    }
    this.checkLogin();
  }

  ngOnInit(): void {
   /* this.route.params.subscribe(params => {
      const orderId = params['order_id'];
      console.log('Order ID:', orderId);
    });*/
    this.registrationForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
      contact: ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
      course_id: ['', [Validators.required]],
    });

    this.registrationOtpForm = this.formBuilder.group({
      otp: ['', [Validators.required]]
    });

    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });

    this.enrollmentVerifyForm = this.formBuilder.group({
      'enrollment_no': ['', Validators.required]
    });

    this.updatePasswordForm = this.formBuilder.group({
      contact: [{'value': '', disabled: true}],
      otp: ['', [Validators.required]],
      password: ['', [Validators.required]],
      confirm_password: ['', [Validators.required]]
    });

    this.getCourses();
  }

  public toggle() {
    this.signup = !this.signup;
  }
  checkOrderId(data) {
    const params = {
      order_id : data,
    }
    this.studentService.checkOrderId(params).subscribe(fs => {
      if(fs && fs['data'] && fs['data']['status'] && (fs['data']['status'] == "success") && fs['data']['data'] && fs['data']['data']['status'] && (fs['data']['data']['status'] == "paid") )
      {
        this.directStudentData = fs['data']['data'];
        const params = {
          name : this.directStudentData['name'],
          contact: this.directStudentData['phone'],
          email: this.directStudentData['email'],
          paid_at: this.directStudentData['paid_at'],
          user_id: this.directStudentData["notes"][0]['value'],
          amount: this.directStudentData["details"][0]['amount'],
        }
        this.studentService.sendMessage(params).subscribe(data => {
        });
      }
       else {
        window.location.href = 'https://forms.cdoevgu.com/';
      }
    }, error => {
      window.location.href = 'https://forms.cdoevgu.com/';
    });
  }
  getCourses() {
    this.courses = [];
    this.courseService.get({'page': -1, 'status': 'ACTIVE'}).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.courses = data['data'];
      } else {
        this.courses = [];
      }
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

  checkLogin() {
    if (StorageService.getItem('accessToken') && StorageService.getItem('self')) {
      this.globalService.setAccessToken(StorageService.getItem('accessToken'));
      this.globalService.setSelf(StorageService.getItem('self'));
      this.reloadSelf();
    } else {
      this.router.navigateByUrl('auth/login');
    }
  }

  reloadSelf() {
    this.authService.self({all: true}).subscribe((data) => {
      this.globalService.setSelf({
        'name': data.name,
        'user_id': data.id,
        'username': data.username,
        'email': data.email,
        'contact': data.contact,
        'belongs_to': data.belongs_to
      });
      this.router.navigateByUrl('general/home');
    }, e => {
      StorageService.clearAll();
      this.router.navigateByUrl('auth/login');
    });
  }

  register() {
    if (this.agreeTermsCondtions) {
      this.submitted = true;
      if (!this.registrationForm.valid) {
        return;
      }
      if (this.submitted) {
        if (this.checkLoggedInStatus()) {
          this.setSelfData();
          this.router.navigateByUrl('general/home');
        } else {
          let params = {
            'name': (this.registrationForm && this.registrationForm.getRawValue() && this.registrationForm.getRawValue().name) ? this.registrationForm.getRawValue().name : '',
            'email': (this.registrationForm && this.registrationForm.getRawValue() && this.registrationForm.getRawValue().email) ? this.registrationForm.getRawValue().email : '',
            'contact': (this.registrationForm && this.registrationForm.getRawValue() && this.registrationForm.getRawValue().contact) ? this.registrationForm.getRawValue().contact : '',
            'course_id': (this.registrationForm && this.registrationForm.getRawValue() && this.registrationForm.getRawValue().course_id) ? this.registrationForm.getRawValue().course_id : ''
          };
          this.submitting = true;
          this.studentService.create(params).subscribe(data => {
            this.submitting = false;
            this.submitted = false;
            this.registrationForm.patchValue({
              'name': '',
              'email': '',
              'contact': '',
              'course_id': ''
            });
            this.responseRegister = data;
            this.openOtpModal();
          }, error => {
            this.submitting = false;
            this.submitted = false;
          });
        }
      }
    } else {
      this.alertService.showErrors('Please agree to our terms & condtions to register');
    }
  }

  openOtpModal() {
    this.modalType = 'USER_REGISTRATION_OTP';
    this.modalService.open(this.templateRef);
    this.resendOTPTimer();
  }

  resendOTP() {
    this.submitting = true;
    this.studentService.resendOTP({'user_id': this.responseRegister.user_id}).subscribe(data => {
      if (data && data['decoded_otp']) {
        this.decodedOtp = data['decoded_otp'];
        this.resendOTPTimer();
      }
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

  resendOTPTimer() {
    this.timerSixtySeconds = 60;
    this.interval = setInterval(() => {
      if (this.timerSixtySeconds > 0) {
        this.timerSixtySeconds--;
      } else {
        this.interval = undefined;
      }
    }, 1000);
  }

  submitOtp() {
    this.submittedOtp = true;
    if (!this.registrationOtpForm.valid) {
      return;
    }
    if (this.submittedOtp) {
      this.studentService.verifyOTP({
        'user_id': this.responseRegister.user_id,
        'otp': this.registrationOtpForm.value.otp,
        'decoded_otp': this.responseRegister.decoded_otp,
        'session_id': this.responseRegister.session_id
      }).subscribe(data => {
        if (data && data['user_id']) {
          this.modalService.dismissAll('');
          this.globalService.setAccessToken(data.token);
          this.globalService.setSelf({
            'name': data.name,
            'email': data.email,
            'contact': data.contact,
            'user_id': data.user_id,
            'username': data.username,
            'belongs_to': data.belongs_to
          });
          this.router.navigateByUrl('general/home');
        }
        this.submittingOtp = false;
      }, error => {
        this.submittingOtp = false;
      });
    }
  }

  checkLoggedInStatus() {
    const accessToken = StorageService.getItem('accessToken');
    return accessToken && accessToken !== 'null';
  }

  setSelfData() {
    this.authService.self({all: true}).subscribe((data) => {
      this.isBusy = false;
      this.globalService.setSelf({
        'name': data.name,
        'user_id': data.id,
        'username': data.username,
        'email': data.email,
        'contact': data.contact,
        'belongs_to': data.belongs_to
      });
      return;
    }, e => {
      this.isBusy = false;
      StorageService.clearAll();
    });
  }

  signIn() {
    /*this.alertService.showErrors('System under maintenance');
    return false;*/
    this.submittedLogin = true;
    if (!this.loginForm.valid) {
      return;
    }
    if (this.submittedLogin) {
      if (this.checkLoggedInStatus()) {
        this.setSelfData();
        this.router.navigateByUrl('general/home');
      } else {
        this.authService.login(this.loginForm.getRawValue()).subscribe(data => {
          if (data.id) {
            this.globalService.setAccessToken(data.token);
            this.globalService.setSelf({
              'name': data.name,
              'email': data.email,
              'contact': data.contact,
              'user_id': data.id,
              'username': data.username,
              'belongs_to': data.belongs_to
            });
            this.router.navigateByUrl('general/home');
            this.loginForm.reset();
          }
        }, error => {
          this.isBusy = false;
          this.submittedLogin = false;
        });
      }
    }
  }

  openForgotPasswordModal(content) {
    /*this.alertService.showErrors('System under maintenance');
    return false;*/
    this.modalType = 'FORGOT_PASSWORD';
    this.modalService.open(content);
  }

  verifyEnrollmentNo() {
    this.submittedEnrollmentNoVerify = true;
    if (!this.enrollmentVerifyForm.valid) {
      return;
    }
    if (this.submittedEnrollmentNoVerify) {
      this.authService.verifyEnrollmentNo(this.enrollmentVerifyForm.getRawValue()).subscribe(data => {
        if (data) {
          this.decodedResponseOtp = (data && data['decoded_otp']) ? data['decoded_otp'] : '';
          this.updatePasswordForm.patchValue({
            'contact': data.contact
          });
          this.modalService.dismissAll('');
          this.modalType = 'RESET_PASSWORD';
          this.modalService.open(this.templateRef);
          this.submittedEnrollmentNoVerify = false;
        }
      }, error => {
        this.submittedEnrollmentNoVerify = false;
      });
    }
  }

  resetPassword() {
    this.submitted = true;
    if (!this.updatePasswordForm.valid) {
      return;
    }
    this.submittingPassword = true;
    if (this.updatePasswordForm.value.password === this.updatePasswordForm.value.confirm_password) {
      let params = {
        'password': this.updatePasswordForm.getRawValue().password,
        'contact': this.updatePasswordForm.getRawValue().contact,
        'otp': this.updatePasswordForm.getRawValue().otp,
        'decoded_otp': this.decodedResponseOtp,
      };
      this.authService.resetPassword(params).subscribe(data => {
        this.modalService.dismissAll('');
        this.submittingPassword = false;
      }, error => {
        this.submittingPassword = false;
      });
    } else {
      this.submittingPassword = false;
      this.alertService.showErrors('Password and Confirm Password didn\'t match');
    }
  }
}
