<div class="container mt-50px">
  <div class="row p-10">
    <div class="col-1"></div>
    <div class="col-10">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-12 text-center">
              <img src="assets/images/logo/vgu-fabicon.png" alt="Subharti University" style="max-width: 300px">
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row" *ngIf="transactionType === 'SUCCESS'">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
              <i class="fa-solid fa-circle-check fa-4x text-success"></i>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center mt-20px">
              <h5 class="text-italic">Payment Successful</h5>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 text-center mt-20px">
              <button class="btn btn-primary"
                      (click)="openUrl(environmentUrl+paymentFor+'/payment-receipt/'+uniqueId+'-'+studentData['session_id'])">
                <i class="fa-solid fa-receipt mr-8px"></i>Download Invoice
              </button>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 text-center mt-20px">
              <button class="btn btn-primary"
                      (click)="goTo('https://student.subhartide.com')">
                <i class="fa-solid fa-receipt mr-8px"></i>Go Back Home
              </button>
            </div>
          </div>
          <div class="row" *ngIf="transactionType === 'FAILED'">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
              <i class="fa-solid fa-circle-xmark fa-4x text-danger"></i>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center mt-20px">
              <h5 class="text-italic">Payment Failed, Please try again</h5>
            </div>
          </div>
          <div class="row" *ngIf="transactionType === 'CANCELLED'">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
              <i class="fa-solid fa-circle-xmark fa-4x text-danger"></i>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center mt-20px">
              <h5 class="text-italic">Payment Cancelled, Please complete your payment</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-1"></div>
  </div>
</div>
