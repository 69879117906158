<div class="authentication-main auth-v2" style="background-image: url('assets/images/other-images/final-background.jpg');">
  <ng-template #content let-modal>
    <div id="modal">
      <div class="modal-header font-weight-bold">
        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'FORGOT_PASSWORD'">Forgot Password</h4>
        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'RESET_PASSWORD'">Reset Password</h4>
        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'USER_REGISTRATION_OTP'">User Registration OTP</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="modalService.dismissAll('')"
        >
          ×
        </button>
      </div>
      <div class="modal-body" ngbAutoFocus>
        <div class="row" *ngIf="modalType === 'USER_REGISTRATION_OTP'">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <form class="form" novalidate [formGroup]="registrationOtpForm">
              <div class="row">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                  <fieldset class="form-group">
                    <input class="form-control" type="number" formControlName="otp"
                           placeholder="Enter OTP">
                    <small *ngIf="submittedOtp && registrationOtpForm.get('otp').hasError('required')"
                           class="text-danger">
                      Field is required.
                    </small>
                  </fieldset>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="row" *ngIf="modalType === 'FORGOT_PASSWORD'">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <form class="form" novalidate [formGroup]="enrollmentVerifyForm">
                  <div class="row">
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <fieldset class="form-group">
                        <label>Enrollment No.</label>
                        <input class="form-control" type="text" formControlName="enrollment_no" placeholder="Enter Enrollment No.">
                        <small *ngIf="submittedEnrollmentNoVerify && enrollmentVerifyForm.get('enrollment_no').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </fieldset>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="modalType === 'RESET_PASSWORD'">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <form class="form" novalidate [formGroup]="updatePasswordForm">
                  <div class="row">
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label">Verification Code Sent to</label>
                        <input class="form-control" formControlName="contact"/>
                        <small *ngIf="submitted && updatePasswordForm.get('contact').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label d-flex">
                          Password
                          <i (click)="inputTypePassword = 'text'"
                             [ngClass]="{'display-hidden': (inputTypePassword === 'text')}"
                             class="fas fa-eye-slash d-flex align-items-center pl-1 cursor-pointer f_10"></i>
                          <i (click)="inputTypePassword = 'password'"
                             [ngClass]="{'display-hidden': (inputTypePassword === 'password')}"
                             class="fas fa-eye d-flex align-items-center pl-1  cursor-pointer f_10"></i>
                        </label>
                        <input class="form-control" [type]="inputTypePassword" formControlName="password"/>
                        <small *ngIf="submitted && updatePasswordForm.get('password').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label d-flex">
                          Confirm Password
                          <i (click)="inputTypeConfirmPassword = 'text'"
                             [ngClass]="{'display-hidden': (inputTypeConfirmPassword === 'text')}"
                             class="fas fa-eye-slash d-flex align-items-center pl-1 cursor-pointer f_10"></i>
                          <i (click)="inputTypeConfirmPassword = 'password'"
                             [ngClass]="{'display-hidden': (inputTypeConfirmPassword === 'password')}"
                             class="fas fa-eye d-flex align-items-center pl-1  cursor-pointer f_10"></i>
                        </label>
                        <input class="form-control" [type]="inputTypeConfirmPassword" formControlName="confirm_password"/>
                        <small *ngIf="submitted && updatePasswordForm.get('confirm_password').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label">Verification Code</label>
                        <input class="form-control" formControlName="otp" type="number"/>
                        <small *ngIf="submitted && updatePasswordForm.get('otp').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-1" *ngIf="modalType === 'USER_REGISTRATION_OTP'">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-right">
            <small>Resend OTP in - </small>
            <small class="text-danger mr-1"
                   *ngIf="timerSixtySeconds > 0">{{timerSixtySeconds}} sec.</small>
            <small class="text-primary cursor-pointer"
                   *ngIf="timerSixtySeconds === 0" (click)="resendOTP()">Resend OTP ?
            </small>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center">
            <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                    (click)="submitOtp()"
                    [disabled]="submittingOtp" *ngIf="modalType === 'USER_REGISTRATION_OTP'">
              <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submittingOtp"></i>Submit
            </button>
            <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                    (click)="verifyEnrollmentNo()"
                    [disabled]="submittingOtp" *ngIf="modalType === 'FORGOT_PASSWORD'">
              <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submittingOtp"></i>Verify
            </button>
            <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                    (click)="resetPassword()"
                    [disabled]="submittingPassword" *ngIf="modalType === 'RESET_PASSWORD'">
              <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submittingPassword"></i>Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <div class="row m-0">
    <a class="brand-logo">
      <img src="assets/images/logo/new-logo-png.png" alt="brand-logo" height="100"/>
    </a>
    <div class="d-none d-lg-flex col-lg-7 align-items-center p-5">
      <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
        <img class="img-fluid" width="700px" height="600px"
             src="assets/images/other-images/student-side.png"
             alt="Login V2"/>
      </div>
    </div>
    <div class="d-flex col-lg-5 align-items-center  px-2 p-lg-5 ">
      <div class="col-8 col-sm-8 col-md-6 col-lg-10 px-xl-4 mx-auto login-card ">
        <h1 class=" text-center" style="color: #2A413B">
          <img src="assets/images/logo/new-logo-png.png" alt="VGU" height="100px"/>
        </h1>
        <h5 class=" text-center" style="color: #2A413B">Login  to <strong>Student Panel</strong> and start exploring !</h5>
        <form class="auth-login-form mt-2" [formGroup]="loginForm">
          <div class="form-group text-left ">
            <label class=""><strong>Username</strong></label>
            <input class="form-control border-radius-0" type="text" placeholder="Enrollment No. / Email"
                   formControlName="username"/>
          </div>
          <div class="form-group text-left">
            <label class="col-form-label"><strong>Password</strong></label>
            <input class="form-control border-radius-0" type="password" placeholder="*******"
                   formControlName="password"/>
            <small class="text-primary">Use your Contact No. or DOB (in YYYY-MM-DD) format as password</small>
          </div>
          <div class="form-group text-left text-secondary ">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <!--<div class="checkbox p-0 text-left">
                  <input id="checkbox2" type="checkbox" [(ngModel)]="agreeTermsCondtions" [ngModelOptions]="{standalone: true}">
                  <label for="checkbox2" class="f_12">
                    <small>I agree to terms & conditions</small>
                  </label>
                </div>-->
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                <small class="col-form-label f_12 cursor-pointer" (click)="openForgotPasswordModal(content)">Forgot Password ?</small>
              </div>
            </div>
          </div>
          <div class="form-group form-row mt-3 mb-0">
            <button class="btn btn-primary btn-block" type="submit" (click)="signIn()">Log In</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
